export const PFCurrentStage4Side2 = [
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 7.15,
    rank: 1,
    avg_round: 34136,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 5.22,
    rank: 2,
    avg_round: 34422,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 4.98,
    rank: 3,
    avg_round: 37354,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 4.93,
    rank: 4,
    avg_round: 33697,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 4.68,
    rank: 5,
    avg_round: 35922,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 2.66,
    rank: 6,
    avg_round: 38917,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 2.45,
    rank: 7,
    avg_round: 35361,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 2.14,
    rank: 8,
    avg_round: 33477,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.95,
    rank: 9,
    avg_round: 35008,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 1.87,
    rank: 10,
    avg_round: 35839,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 1.85,
    rank: 11,
    avg_round: 36902,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 1.74,
    rank: 12,
    avg_round: 34994,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 1.73,
    rank: 13,
    avg_round: 33460,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.31,
    rank: 14,
    avg_round: 37944,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 1.31,
    rank: 14,
    avg_round: 34133,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 1.24,
    rank: 16,
    avg_round: 36106,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 1.08,
    rank: 17,
    avg_round: 39905,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 1.04,
    rank: 18,
    avg_round: 34795,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.95,
    rank: 19,
    avg_round: 32678,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.94,
    rank: 20,
    avg_round: 37390,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.94,
    rank: 20,
    avg_round: 35859,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.88,
    rank: 22,
    avg_round: 31562,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.87,
    rank: 23,
    avg_round: 39633,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.66,
    rank: 24,
    avg_round: 38837,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.62,
    rank: 25,
    avg_round: 36737,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.6,
    rank: 26,
    avg_round: 27452,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.6,
    rank: 26,
    avg_round: 39583,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.59,
    rank: 28,
    avg_round: 39908,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.59,
    rank: 28,
    avg_round: 33450,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.58,
    rank: 30,
    avg_round: 38547,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.55,
    rank: 31,
    avg_round: 38835,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.52,
    rank: 32,
    avg_round: 31350,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.51,
    rank: 33,
    avg_round: 38330,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.48,
    rank: 34,
    avg_round: 37634,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.47,
    rank: 35,
    avg_round: 31079,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.46,
    rank: 36,
    avg_round: 34917,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.45,
    rank: 37,
    avg_round: 31290,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.43,
    rank: 38,
    avg_round: 27084,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Robin',
    app_rate: 0.41,
    rank: 39,
    avg_round: 30599,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.4,
    rank: 40,
    avg_round: 34881,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.39,
    rank: 41,
    avg_round: 25101,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.38,
    rank: 42,
    avg_round: 35914,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.35,
    rank: 43,
    avg_round: 37201,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.33,
    rank: 44,
    avg_round: 34681,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Jiaoqiu',
    app_rate: 0.32,
    rank: 45,
    avg_round: 31080,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.32,
    rank: 45,
    avg_round: 36163,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.32,
    rank: 45,
    avg_round: 34474,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.32,
    rank: 45,
    avg_round: 33384,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.32,
    rank: 45,
    avg_round: 26577,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.32,
    rank: 45,
    avg_round: 31193,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.28,
    rank: 51,
    avg_round: 27905,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.27,
    rank: 52,
    avg_round: 33963,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 53,
    avg_round: 29971,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.26,
    rank: 53,
    avg_round: 38912,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 53,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 53,
    avg_round: 30444,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.26,
    rank: 53,
    avg_round: 33924,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.25,
    rank: 58,
    avg_round: 32196,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.23,
    rank: 59,
    avg_round: 28582,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 59,
    avg_round: 24104,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 61,
    avg_round: 33922,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.22,
    rank: 61,
    avg_round: 38917,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.21,
    rank: 63,
    avg_round: 31940,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.21,
    rank: 63,
    avg_round: 27810,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.2,
    rank: 65,
    avg_round: 34140,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 66,
    avg_round: 27906,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.19,
    rank: 66,
    avg_round: 37345,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.18,
    rank: 68,
    avg_round: 30711,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Huohuo',
    app_rate: 0.18,
    rank: 68,
    avg_round: 31417,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.18,
    rank: 68,
    avg_round: 32455,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.18,
    rank: 68,
    avg_round: 29150,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 68,
    avg_round: 32303,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.18,
    rank: 68,
    avg_round: 39581,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 74,
    avg_round: 31199,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.17,
    rank: 74,
    avg_round: 38738,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.16,
    rank: 76,
    avg_round: 33000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 76,
    avg_round: 35715,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 76,
    avg_round: 30703,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.16,
    rank: 76,
    avg_round: 32917,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.16,
    rank: 76,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 76,
    avg_round: 35182,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.16,
    rank: 76,
    avg_round: 37485,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 83,
    avg_round: 37774,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 83,
    avg_round: 35760,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 83,
    avg_round: 26924,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 83,
    avg_round: 35109,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 83,
    avg_round: 34021,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 83,
    avg_round: 34674,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 83,
    avg_round: 33779,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 83,
    avg_round: 34403,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.14,
    rank: 83,
    avg_round: 30211,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 92,
    avg_round: 36916,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.13,
    rank: 92,
    avg_round: 22067,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.13,
    rank: 92,
    avg_round: 33235,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.13,
    rank: 92,
    avg_round: 36843,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 92,
    avg_round: 39056,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 97,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 97,
    avg_round: 36016,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 97,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.12,
    rank: 97,
    avg_round: 32963,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 101,
    avg_round: 24507,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 101,
    avg_round: 30722,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.11,
    rank: 101,
    avg_round: 31958,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 101,
    avg_round: 24035,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.11,
    rank: 101,
    avg_round: 27360,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.11,
    rank: 101,
    avg_round: 31746,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.11,
    rank: 101,
    avg_round: 38337,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 101,
    avg_round: 26049,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 101,
    avg_round: 33986,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 110,
    avg_round: 28349,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.1,
    rank: 110,
    avg_round: 33755,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 110,
    avg_round: 27986,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 110,
    avg_round: 32834,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 110,
    avg_round: 36488,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 110,
    avg_round: 33867,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.1,
    rank: 110,
    avg_round: 33819,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 110,
    avg_round: 27404,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 110,
    avg_round: 28199,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 110,
    avg_round: 22196,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 110,
    avg_round: 33777,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.1,
    rank: 110,
    avg_round: 35512,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 110,
    avg_round: 23393,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 123,
    avg_round: 30187,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 123,
    avg_round: 30931,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 123,
    avg_round: 27891,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 123,
    avg_round: 38077,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 123,
    avg_round: 25056,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 123,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 123,
    avg_round: 31328,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 123,
    avg_round: 35335,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 123,
    avg_round: 30788,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 123,
    avg_round: 37995,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 123,
    avg_round: 36426,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 123,
    avg_round: 34866,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 135,
    avg_round: 37946,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 135,
    avg_round: 30155,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 35560,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.08,
    rank: 135,
    avg_round: 30776,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.08,
    rank: 135,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 135,
    avg_round: 22648,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 36434,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 135,
    avg_round: 28962,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 36418,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 135,
    avg_round: 30931,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 29812,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 27476,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 36830,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 135,
    avg_round: 36331,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 135,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.08,
    rank: 135,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 135,
    avg_round: 24114,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 135,
    avg_round: 25169,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 135,
    avg_round: 27912,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 35747,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 135,
    avg_round: 25686,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Tingyun',
    app_rate: 0.08,
    rank: 135,
    avg_round: 25864,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 135,
    avg_round: 34624,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 25020,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 135,
    avg_round: 38261,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 135,
    avg_round: 35274,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.07,
    rank: 161,
    avg_round: 31582,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 161,
    avg_round: 36065,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 161,
    avg_round: 28183,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 161,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Bailu',
    app_rate: 0.07,
    rank: 161,
    avg_round: 29112,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 161,
    avg_round: 29296,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.07,
    rank: 161,
    avg_round: 31364,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.07,
    rank: 161,
    avg_round: 27457,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 161,
    avg_round: 36033,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 161,
    avg_round: 35607,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 161,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 161,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 161,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lynx',
    app_rate: 0.07,
    rank: 161,
    avg_round: 33660,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 161,
    avg_round: 32177,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 161,
    avg_round: 35983,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 161,
    avg_round: 32999,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 161,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 161,
    avg_round: 31700,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.07,
    rank: 161,
    avg_round: 20191,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 161,
    avg_round: 34629,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 161,
    avg_round: 27586,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 21669,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 22722,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.06,
    rank: 183,
    avg_round: 32277,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29608,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 25880,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 183,
    avg_round: 38371,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 31804,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 38080,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 38585,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-harmony',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 34162,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 33230,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Clara',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 32840,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29642,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 33342,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 26834,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 32752,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29195,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 27920,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 25266,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 23711,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 24674,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 30547,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29886,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 21960,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.06,
    rank: 183,
    avg_round: 35690,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'march-7th',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.06,
    rank: 183,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 35188,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 183,
    avg_round: 31536,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 183,
    avg_round: 29316,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 183,
    avg_round: 30144,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 183,
    avg_round: 33893,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 31090,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 38445,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 38375,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Blade',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 29423,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 25297,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 35158,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 23790,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 21340,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 37380,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 29396,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30014,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30173,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 28876,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30128,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30668,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 24961,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 31296,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 36582,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 27219,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 32594,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 33988,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 34428,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 22553,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 224,
    avg_round: 33923,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-harmony',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 32652,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30507,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 28228,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 24026,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 224,
    avg_round: 31000,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 28472,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30112,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 36526,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 30716,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 27520,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 224,
    avg_round: 35418,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 34482,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 32001,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 224,
    avg_round: 32031,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Sparkle',
    app_rate: 0.04,
    rank: 224,
    avg_round: 25356,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 224,
    avg_round: 28860,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.04,
    rank: 224,
    avg_round: 28200,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 224,
    avg_round: 34061,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 224,
    avg_round: 24686,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 224,
    avg_round: 22233,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 24936,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30023,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 32768,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Serval',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 33565,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'march-7th-swordmaster',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 275,
    avg_round: 28420,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 31673,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 25995,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 27804,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 32135,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 29748,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 23945,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 38935,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 21198,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 275,
    avg_round: 20618,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 33603,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30524,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Serval',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 36425,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 28648,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 275,
    avg_round: 32659,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 29295,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 28471,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34018,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 33598,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 25935,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 25691,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30691,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 31655,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Pela',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 275,
    avg_round: 31688,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 32170,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 275,
    avg_round: 36085,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 36565,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 33068,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30928,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 20000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34008,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34138,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34845,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 21854,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34727,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 32288,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Serval',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 34437,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Pela',
    char_four: 'march-7th',
    app_rate: 0.03,
    rank: 275,
    avg_round: 26780,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 275,
    avg_round: 33206,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 27840,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Argenti',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 31982,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30950,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 275,
    avg_round: 30368,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'march-7th-swordmaster',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 275,
    avg_round: 31323,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 29265,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 275,
    avg_round: 29365,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 25215,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 275,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33472,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Blade',
    char_three: 'black-swan',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 350,
    avg_round: 35187,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27058,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20871,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 350,
    avg_round: 26875,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 36513,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 34131,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32599,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sampo',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31880,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Sampo',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28402,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25794,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 34427,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 21856,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 40000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Argenti',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31410,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31778,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28775,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25306,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27878,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Tingyun',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 36925,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 22450,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 36370,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 36282,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 37870,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 37975,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Serval',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 29886,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 30272,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 24715,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32888,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20714,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31577,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20627,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20033,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25757,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33288,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25100,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25790,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27822,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Clara',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 30479,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33558,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31195,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jade',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 35630,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33700,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Bronya',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28754,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32454,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20035,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33669,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27500,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 29856,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 37380,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32929,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Sampo',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31635,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 30488,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20167,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 350,
    avg_round: 34700,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Blade',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 22597,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Sampo',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 21604,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 30620,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 38655,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 36890,
    star_num: '4'
  },
  {
    char_one: 'Serval',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sampo',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27781,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20000,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 34900,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Yunli',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31598,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Acheron',
    char_three: 'Kafka',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 24222,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 29013,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 30319,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Herta',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 22952,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 25485,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Clara',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31768,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Herta',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28252,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'Herta',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 34126,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Clara',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32422,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20035,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28440,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27620,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 20461,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Yunli',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33043,
    star_num: '4'
  },
  {
    char_one: 'Rappa',
    char_two: 'Firefly',
    char_three: 'trailblazer-harmony',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Clara',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 27943,
    star_num: '4'
  },
  {
    char_one: 'Blade',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'march-7th',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28682,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 33915,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 350,
    avg_round: 26044,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'black-swan',
    char_two: 'Sampo',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 29195,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 350,
    avg_round: 28228,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Blade',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Yunli',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 350,
    avg_round: 31680,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Clara',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 350,
    avg_round: 26187,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 350,
    avg_round: 32965,
    star_num: '4'
  }
];
