export const HSRPFHisto = [
  {
    slug: 'acheron',
    score: 32669,
    usage: 56.05,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 31482,
    usage: 7.6,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 22000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 28112,
    usage: 1.25,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 33303,
    usage: 37.96,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 30185,
    usage: 1.07,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 32260,
    usage: 37.0,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 31076,
    usage: 2.72,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 22000,
    usage: 0.22,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 30332,
    usage: 5.81,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 31757,
    usage: 9.88,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 22000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 22764,
    usage: 0.26,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 30887,
    usage: 12.79,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 28607,
    usage: 41.81,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 32793,
    usage: 35.45,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 29985,
    usage: 48.95,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 31782,
    usage: 2.94,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 25330,
    usage: 2.55,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 27747,
    usage: 0.45,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 33129,
    usage: 33.67,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 30445,
    usage: 37.74,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 22000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 33802,
    usage: 28.19,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 22000,
    usage: 1.43,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 34757,
    usage: 19.14,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 34600,
    usage: 24.21,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 26226,
    usage: 4.58,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 24791,
    usage: 0.64,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 32337,
    usage: 40.56,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 34888,
    usage: 23.91,
    role: 'sustain',
    new: true
  },
  {
    slug: 'luka',
    score: 22000,
    usage: 0.01,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 32024,
    usage: 12.15,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 31711,
    usage: 1.88,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 33250,
    usage: 0.36,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 29913,
    usage: 4.18,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 22000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 22000,
    usage: 0.11,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 26960,
    usage: 0.13,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 31715,
    usage: 16.83,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 24431,
    usage: 0.16,
    role: 'dps'
  },
  {
    slug: 'rappa',
    score: 33565,
    usage: 23.22,
    role: 'specialist',
    new: true
  },
  {
    slug: 'robin',
    score: 34077,
    usage: 47.13,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 31482,
    usage: 79.57,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 25877,
    usage: 1.21,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 27980,
    usage: 1.81,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 26882,
    usage: 1.14,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 27815,
    usage: 3.57,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 31436,
    usage: 27.07,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 22000,
    usage: 0.01,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 31328,
    usage: 15.79,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 28585,
    usage: 5.01,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-preservation',
    score: 27414,
    usage: 0.36,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-harmony',
    score: 29096,
    usage: 52.86,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-destruction',
    score: 22000,
    usage: 0.01,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 24764,
    usage: 0.43,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 26451,
    usage: 0.63,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 22000,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 22000,
    usage: 0.05,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 33911,
    usage: 13.11,
    role: 'dps'
  }
];
